/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { colors, device } from '../components/layout/GlobalStyles';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { FAQ } from '../components/Faq';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';
import { Seo } from '../components/Seo';

const StyledServices = styled.div`
    max-width: 1350px;
    margin: 0 auto;
    ul {
        padding-left: 4em;
        li {
            margin: 0.2em 0em;
        }
    }
    h3 {
        margin: 1em 0em;
    }
    p {
    }
    h4 {
        margin: 1em 0em;
    }
    .yellow-title {
        margin: 0em;
        font-size: 32px;
    }
    h4 {
        font-size: 42px;
        margin-bottom: 0.5em;
    }
    h5 {
        padding-left: 0.5em;
        font-weight: 900;
        color: ${colors.navy};
        font-size: 28px;
    }
    h3 {
        padding: 0em 0.4em;
    }
    button {
        margin-top: 1em;
    }
    p {
        padding: 0em 0.75em;
        margin: 1em 0em;
    }
    .yellow-paragraph {
        padding: 0em;
        margin: 0em 0em;
    }
    @media ${device.laptop} {
        max-width: 1050px;
    }
    @media ${device.laptopL} {
        max-width: 1280px;
    }
    @media ${device.desktop} {
    }
`;

const Blog = ({ data }) => {
    const { mdx } = data;
    console.log(mdx.body);
    const { meta } = mdx.frontmatter;
    return (
        <DefaultLayout>
            <Seo {...meta} />
            <RegularHero blog navy reverse prevPage="home" currentPage={mdx.frontmatter.title}>
                <div className="new-image">
                    <GatsbyImage
                        className="regular-hero-image"
                        quality={100}
                        image={getImage(mdx.frontmatter.images[0])}
                        placeholder="none"
                        alt="invisalign set of teeth"
                    />
                </div>
            </RegularHero>
            <StyledServices>
                <MDXRenderer questions={mdx.exports.questions} images={mdx.frontmatter.images}>
                    {mdx.body}
                </MDXRenderer>
            </StyledServices>
        </DefaultLayout>
    );
};

export const blogQuery = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                title
                slug
                images {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: BLURRED)
                    }
                }
            }
            exports {
                questions {
                    answer
                    question
                }
            }
            body
        }
    }
`;

export default Blog;
